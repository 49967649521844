import React from "react";
import LayoutMain from "../../layouts/LayoutMain";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import Feature from "../../components/ui/Feature.js";
import { Row, Col, Divider, Typography } from "antd";
import { StopOutlined, LinkOutlined, BorderOutlined } from "@ant-design/icons";
import LearnMore from "../../components/sections/LearnMore.js";

const { Title, Paragraph } = Typography;

const FeaturesContentPage = () => (
  <LayoutMain>
    <Seo
      title="Features | Integration"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      className="heading"
      style={{ backgroundColor: "#fff" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>Integration</Title>
        <Title level={2} style={{ color: "white" }}>
          Include inFlow charts in your product by embeds or linking.
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <BorderOutlined
                style={{ marginRight: "12px", color: "#EBD247" }}
              />
              Embedding
              {/* <Tag>Lite</Tag> */}
            </Title>
            <Paragraph>
              Include charts with smart embed technology, providing a great
              native-like user-experience.
            </Paragraph>
          </Col>
          <Feature
            title="Resizing iFrame"
            description="If your chart resizes the embed will size its dimensions accordingly. No need for scrollbars."
          />
          <Feature
            title="Parent page scrolling"
            description="Scroll the parent page based on the user interaction in the inFlow chart."
          />
          <Feature
            title="In-page links"
            description="Navigate the parent page based on the input of the user in the chart, and vice-versa."
          />
          <Feature
            title="Cross-domain iFrames"
            description="Domain authentication for cross domain iFrames."
          />
          <Feature
            title="Mutiple charts"
            description="Include multiple inFlow charts on a single webpage."
          />
          <Col>
            <Paragraph>
              Learn more about scalable embeds in our{" "}
              <Link to="/docs/embedding-charts/introduction">
                Documentation
              </Link>
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <LinkOutlined style={{ marginRight: "12px", color: "#EBD247" }} />
              Chart viewer
              {/* <Tag>Lite</Tag> */}
            </Title>
            <Paragraph>
              Link or share the fullscreen chart view for a complete experience.
            </Paragraph>
          </Col>
          <Feature
            title="Fullscreen"
            description="Offer a fullscreen view for your charts. Works great for schematic interactions."
          />
          <Feature
            title="Easy sharing"
            description="We provide short URLs for easy sharing of charts."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
            >
              <StopOutlined style={{ marginRight: "12px", color: "#EBD247" }} />{" "}
              Privacy options
              {/* <Tag>Pro</Tag> */}
              {/* <Tag>Premium</Tag> */}
            </Title>
          </Col>
          <Feature
            title="Password protection"
            description="We can password protect your content using Basic Authtentication."
          />
          <Feature
            title="Private environments"
            description="We provide custom environments upon request."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <LearnMore />
  </LayoutMain>
);

export default FeaturesContentPage;
