import React from "react";
import { Typography, Col, Tag } from "antd";
const { Title, Paragraph } = Typography;

const Feature = ({
  title,
  description,
  description2,
  description3,
  isLite,
  isPro,
  isPremium,
  style,
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={8} lg={8} xl={8} style={style}>
        <Title>
          {isLite > 0 && <Tag style={{ margin: "0" }}>Lite</Tag>}
          {isPro > 0 && <Tag style={{ margin: "0" }}>Pro</Tag>}
          {isPremium > 0 && <Tag style={{ margin: "0" }}>Premium</Tag>}
        </Title>
        <Title level={3} style={{ marginRight: "10px" }}>
          {title}
        </Title>
        <Paragraph className="feature-description">{description}</Paragraph>
        <Paragraph className="feature-description">{description2}</Paragraph>
        <Paragraph className="feature-description">{description3}</Paragraph>
      </Col>
    </>
  );
};

export default Feature;
